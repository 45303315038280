<template>
	<footer>
		<div class="footer__area footer-bg-2">
			<div class="footer__top pt-50">
				<div class="container custom-container">
					<div class="row text-center">
						<!-- Logo -->
						<div class="col d-none d-xl-block">
							<div class="footer__widget">
								<div class="footer__widget-title mb-45">
									<div class="footer__logo">
										<router-link to="/">
											<img src="@/assets/cliente/img/logo/logo-white.png" alt="logo">
										</router-link>
									</div>
								</div>
							</div>
						</div>

						<!-- SAC -->
						<div class="col align-self-center align-self-sm-start">
							<div class="footer__widget">
								<div class="footer__widget-title footer__widget-title-4">
									<router-link to="/" class="d-block d-xl-none">
										<img src="@/assets/cliente/img/logo/logo-white.png" alt="logo">
									</router-link>
									<h4 class="pt-5 pt-xl-0 d-none d-sm-block">SAC</h4>
								</div>
								<div class="footer__widget-content d-none d-sm-block">
									<div class="footer__link footer__link-4">
										<ul>
											<li><a :href="'tel:'+ '+5541991724977'"><i class="far fa-phone-alt font-13 me-1"></i> +55 (41) 99172-4977</a></li>
											<li><a :href="'mailto:'+ 'contato@agenciaoneway.com'"><i class="far fa-envelope font-13 me-1"></i> contato@agenciaoneway.com</a></li>
										</ul>
									</div>
								</div>
							</div>
						</div>

						<!-- Mapa do site -->
						<div class="col d-none d-lg-block">
							<div class="footer__widget">
								<div class="footer__widget-title footer__widget-title-4">
									<h4 class="pt-0">Mapa do site</h4>
								</div>
								<div class="footer__widget-content">
									<div class="footer__link footer__link-4">
										<ul>
											<li><router-link to="/termosCompras">Termos de compras</router-link></li>
											<li><a href="javascript:;" @click="setRouteAdmin">Área do cliente</a></li>
										</ul>
									</div>
								</div>
							</div>
						</div>

						<!-- Redes sociais -->
						<div class="col align-self-center align-self-lg-start">
							<div class="footer__widget">
								<div class="footer__widget-title footer__widget-title-4">
									<h4 class="pt-0">Redes sociais</h4>
								</div>
								<div class="footer__widget-content">
									<div class="footer__link footer__link-4">
										<ul>
											<li><a :href="'https://instagram.com/onewayprogramacao'" target="_blank"><i class="fab fa-instagram me-1"></i> Instagram</a></li>
											<li><a :href="'https://discord.gg/monkey-fivem-dev-793484634725548053'" target="_blank"><i class="fab fa-discord me-1"></i> Discord</a></li>
											<li><a :href="'https://wa.me/+5541991724977'" target="_blank"><i class="fab fa-whatsapp me-1"></i> Whatsapp</a></li>
										</ul>
									</div>
								</div>
							</div>
						</div>

						<!-- Endereco -->
						<div class="col d-none d-md-block">
							<div class="footer__widget">
								<div class="footer__widget-title footer__widget-title-4">
									<a href="https://maps.app.goo.gl/DRzPUjEmZwqY3NPs5" target="_blank">
										<img class="w-100 rounded" src="@/assets/cliente/img/banner/localbanner.jpeg" />
									</a>
									<h4><i class="far fa-map-marker-alt font-14 me-1"></i> <span>Escritório</span></h4>
								</div>
								<div class="footer__widget-content">
									<div class="footer__link footer__link-4">
										<ul>
											<li><a href="javascript:;">Av. Paraguai N° 1877</a></li>
											<li><a href="javascript:;">Nações, Fazenda Rio Grande - PR</a></li>
										</ul>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="footer__copyright footer__copyright-2 py-3 footer-bg-2">
				<div class="container custom-container">
					<div class="footer__copyright-text footer__copyright-text-2">
						<p>Copyright © <router-link to="/">Monkey.</router-link> Todos os direitos reservados. By <a href="https://agenciaoneway.com/" target="_blank"> OneWay.</a></p>
					</div>
				</div>
			</div>
		</div>
	</footer>
</template>

<script>

import router from '@/router'

export default {
	name: 'Footer',
	methods: {
		setRouteAdmin : function () {
			this.$store.dispatch('isRouteAdmin', true)
			router.push('/admInicio')
		},
	}
}

</script>

<style scoped>

img[alt="logo"] {
	width: 120px;
}

</style>