<template>
   <div class="bg-lock-screen">
      <div class="wrapper">
         <div class="authentication-lock-screen d-flex align-items-center justify-content-center">
            <div class="card">
               <div class="card-body p-md-5 text-center">
                  <img src="@/assets/admin/images/vrpex3.png" width="300" class="mb-3"> 
                  <h2 class="font-19 mb-3">Portal</h2>
                  <a role="button" class="btn btn-lg btn-block glow-on-hover mx-auto" @click="efetuarLogin">
                     <img src="@/assets/admin/images/logos/discord.png" width="35" class="me-3" alt="logoDiscord">Entrar via discord
                  </a>
                  <p class="mt-3 mb-2"><span class="cursor-pointer" @click="setRouteCliente">Voltar para loja <i class="far fa-external-link-alt font-12 ms-1"></i></span></p>
                  <p class="mt-3 mb-0"><small>by</small> OneWay</p>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>

<script>

import { mapState } from 'vuex'

export default {
	name: 'Login',
   computed: {
		...mapState({
			clientId: state => state.clientId,
			redirectUri: state => state.redirectUri
		})
	},
   methods: {
      setRouteCliente : function () {
         this.$store.dispatch('isRouteAdmin', false)
      },
		efetuarLogin : function () {
         window.location.href = "https://discord.com/api/oauth2/authorize?client_id="+ this.clientId +"&redirect_uri="+ this.redirectUri +"&response_type=token&scope=guilds%20identify%20email";
		}
	},
   mounted() {
      this.$store.dispatch('login')
   }
}

</script>

<style scoped>

.glow-on-hover {
   width: max-content;
   height: 80px !important;
   font-size: 18px !important;
   outline: none;
   color: #fff !important;
   border: 1px solid #0000 !important;
   cursor: pointer;
   position: relative;
   z-index: 0;
   border-radius: 5px;
   padding: 18px 24px;
}

.glow-on-hover:before {
   content: '';
   background: linear-gradient(45deg, #ff0000, #ff7300, #fffb00, #48ff00, #00ffd5, #002bff, #7a00ff, #ff00c8, #ff0000);
   position: absolute;
   top: -2px;
   left:-2px;
   background-size: 400%;
   z-index: -1;
   filter: blur(5px);
   width: calc(100% + 4px);
   height: calc(100% + 4px);
   animation: glowing 20s linear infinite;
   opacity: 0;
   transition: opacity .3s ease-in-out;
   border-radius: 5px;
}

.glow-on-hover:active {
   color: #222427
}

.glow-on-hover:active:after {
   background: transparent;
}

.glow-on-hover:hover:before {
   opacity: 1;
}

.glow-on-hover:after {
   z-index: -1;
   content: '';
   position: absolute;
   width: 100%;
   height: 100%;
   background: var(--color-theme);
   left: 0;
   top: 0;
   border-radius: 5px;
}

@keyframes glowing {
   0% { background-position: 0 0; }
   50% { background-position: 400% 0; }
   100% { background-position: 0 0; }
}

.logo_deck {
   position: fixed;
   bottom: 25px;
   right: 25px;
}

img[alt="logoDiscord"] {
	filter: brightness(30%) grayscale(100%) invert(100%);
}

</style>