<template>
   <header>
      <div class="topbar d-flex align-items-center">
         <nav class="navbar navbar-expand">
            <div class="mobile-toggle-menu" @click="toggleMobileMenu"><i class='bx bx-menu'></i></div>
            <div class="top-menu ms-auto">
               <ul class="navbar-nav align-items-center">
                  <li class="nav-item">
                     <a class="nav-link position-relative" href="javascript:;" role="button" @click="setRouteCliente" title="Voltar para loja">
                        <i class="fal fa-store font-16"></i>
                     </a>
                  </li>
               </ul>
            </div>
            <div class="user-box dropdown">
               <a class="d-flex align-items-center nav-link dropdown-toggle dropdown-toggle-nocaret pe-0" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  <img :src="dadosUsuario.avatar == null ? '' : dadosUsuario.avatar" @error="imageError" class="user-img" alt="Avatar">
                  <div class="user-info ps-2 ms-1">
                     <p class="user-name mt-1 mb-0">{{ String(dadosUsuario.userName).length >= 15 ? String(dadosUsuario.userName).substring(0, 15) + '...' : dadosUsuario.userName }}</p>
                  </div>
               </a>
               <ul class="dropdown-menu dropdown-menu-end">
                  <li>
                     <router-link class="dropdown-item" to="/admPerfil">
                        <i class="far fa-paste me-2"></i><span> Meu perfil</span>
                     </router-link>
                  </li>
                  <li>
                     <a class="dropdown-item" href="javascript:;" @click="deslogar">
                        <i class="far fa-sign-out-alt me-2"></i><span> Sair</span>
                     </a>
                  </li>
               </ul>
            </div>
         </nav>

         <!-- Mudar tema -->
         <div class="switcher-wrapper">
            <div class="switcher-btn" @click="toggleMenuTemas(true)"><i class='bx bx-cog'></i></div>
            <div class="switcher-body">
               <div class="d-flex align-items-center">
                  <h5 class="mb-0">Temas</h5>
                  <button type="button" class="btn-close ms-auto close-switcher" aria-label="Close" @click="toggleMenuTemas(false)"></button>
               </div>
               <hr/>
               <h6 class="mb-3 mt-4">Claro</h6>
               <div class="d-flex align-items-center justify-content-between mb-3">
                  <div class="form-check">
                     <input class="form-check-input" type="radio" name="flexRadioDefault" id="lightmode" :checked="theme == 'light-theme'" @click="toggleTema('light-theme')">
                     <label class="form-check-label bg-danger rounded" for="lightmode"></label>
                  </div>
                  <div class="form-check">
                     <input class="form-check-input" type="radio" name="flexRadioDefault" id="primary2mode" :checked="theme == 'primary2-theme'" @click="toggleTema('primary2-theme')">
                     <label class="form-check-label bg-primary rounded" for="primary2mode"></label>
                  </div>
                  <div class="form-check">
                     <input class="form-check-input" type="radio" name="flexRadioDefault" id="warning2mode" :checked="theme == 'warning2-theme'" @click="toggleTema('warning2-theme')">
                     <label class="form-check-label bg-warning rounded" for="warning2mode"></label>
                  </div>
                  <div class="form-check">
                     <input class="form-check-input" type="radio" name="flexRadioDefault" id="success2mode" :checked="theme == 'success2-theme'" @click="toggleTema('success2-theme')">
                     <label class="form-check-label bg-success rounded" for="success2mode"></label>
                  </div>
               </div>
               <hr/>
               <h6 class="mb-3 mt-4">Escuro</h6>
               <div class="d-flex align-items-center justify-content-between mb-3">
                  <div class="form-check">
                     <input class="form-check-input" type="radio" name="flexRadioDefault" id="dangermode" :checked="theme == 'danger-theme' || theme == null" @click="toggleTema('danger-theme')">
                     <label class="form-check-label bg-danger rounded" for="dangermode"></label>
                  </div>
                  <div class="form-check">
                     <input class="form-check-input" type="radio" name="flexRadioDefault" id="primarymode" :checked="theme == 'primary-theme'" @click="toggleTema('primary-theme')">
                     <label class="form-check-label bg-primary rounded" for="primarymode"></label>
                  </div>
                  <div class="form-check">
                     <input class="form-check-input" type="radio" name="flexRadioDefault" id="darkmode" :checked="theme == 'dark-theme'" @click="toggleTema('dark-theme')">
                     <label class="form-check-label bg-warning rounded" for="darkmode"></label>
                  </div>
                  <div class="form-check">
                     <input class="form-check-input" type="radio" name="flexRadioDefault" id="successmode" :checked="theme == 'success-theme'" @click="toggleTema('success-theme')">
                     <label class="form-check-label bg-success rounded" for="successmode"></label>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </header>
</template>

<script>

import $ from 'jquery'
import router from '@/router'
import { mapState } from 'vuex'

export default {
	name: 'MenuNavBar',
   data: function() {
		return {
			theme: localStorage.theme
		}
	},
   computed: {
      ... mapState({
         errorOnLoadImg: state => state.errorOnLoadImg,
         dadosUsuario: state => state.dadosUsuario,
         urlRest: state => state.urlRest
      })
   },
   methods: {
      imageError : function (e) {
         this.$store.dispatch('imageError', e)
      },
      setRouteCliente : function () {
         router.push('/')
      },
		deslogar : function () {
			this.$store.dispatch('deslogar')
		},
      toggleMobileMenu : function () {
         $(".wrapper").addClass("toggled")
      },
      toggleMenuTemas : function (open) {
         if (open) {
            $(".switcher-wrapper").addClass("switcher-toggled")
         } else {
            $(".switcher-wrapper").removeClass("switcher-toggled")
         }
      },
      toggleTema : function (tema) {
         $("html").attr("class", tema)
         localStorage.theme = tema;
      }
	}
}

</script>

<style scoped>

.form-check label {
	border: 1px solid #000;
	height: 25px;
	width: 25px;
	display: flex;
}

</style>