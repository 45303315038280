<template>
	<header>
      <div class="header__area">
         <div class="header__top d-none d-sm-block text-white">
            <div class="container px-0 px-md-3">
               <div class="row align-items-center">
                  <div class="col-xl-6 col-lg-5 d-none d-lg-block">
                     <div class="header__welcome">
                        <a href="javascript:;" class="limitador" v-if="isLogado">Bem-vindo <i>{{ dadosUsuario.userName }}</i> !</a>
                        <a href="javascript:;" class="limitador" v-else>Bem-vindo visitante!</a>
                     </div>
                  </div>
                  <div class="col-xl-6 col-lg-7 pe-lg-0">
                     <div class="header__action d-flex justify-content-center justify-content-lg-end">
                        <ul>
                           <li><router-link to="/termosCompras">Termos de compras</router-link></li>
                           <li><a href="javascript:;" @click="setRouteAdmin">{{ isLogado ? 'Área do cliente' : 'Fazer login' }}</a></li>
                           <li v-if="isLogado"><a href="javascript:;" @click="deslogar">Deslogar</a></li>
                        </ul>
                     </div>
                  </div>
               </div>
            </div>
         </div>

         <div class="header__info py-xxl-3">
            <div class="container">
               <div class="row align-items-center">
                  <!-- Logo -->
                  <div class="col-lg-2 mb-2 mb-md-4 mb-lg-0">
                     <div class="header__info-left d-flex justify-content-center justify-content-lg-end align-items-center">
                        <div class="logo">
                           <router-link to="/">
                              <img src="@/assets/cliente/img/logo/logo-black.png" alt="logo">
                           </router-link>
                        </div>
                     </div>
                  </div>

                  <!-- Pesquisa -->
                  <div class="col-lg-8 col-md-10 d-none d-md-block">
                     <div class="header__info-right">
                        <div class="header__search w-100">
                           <form action="javascript:;">
                              <div class="header__search-box">
                                 <input type="text" placeholder="Busque pelo nome do pacote" v-model="pesquisa.valor" @keyup.enter="pesquisar">
                                 <button @click="pesquisar"><i class="far fa-search d-inline d-sm-none"></i><span class="d-none d-sm-inline">Pesquisar</span></button>
                              </div>
                              <div class="header__search-cat">
                                 <v-select title="Desktop" :searchable="false" :clearable="false" class="border-0" :options="['Mais recentes', 'Mais antigos', 'Mais vistos', 'Mais vendidos', 'Em promoção']" v-model="pesquisa.filtro" />
                                 <select title="Mobile" class="form-control border-0" v-model="pesquisa.filtro">
                                    <option v-for="(option, index) in ['Mais recentes', 'Mais antigos', 'Mais vistos', 'Mais vendidos', 'Em promoção']" :key="index" :value="option">{{ option }}</option>
                                 </select>
                              </div>
                           </form>
                        </div>
                     </div>
                  </div>

                  <!-- Carrinho -->
                  <div class="col-md-2 d-flex d-md-block justify-content-between justify-content-md-start">
                     <button type="button" class="side-menu-btn offcanvas-toggle-btn ml-25 d-block d-md-none" @click="abrirMenu">
                        <i class="far fa-bars font-20 mb-2"></i>
                     </button>
                     <div class="cart__mini-wrapper p-relative pt-1" v-show="isLogado">
                        <a href="javascript:;" class="cart__toggle" @click="abrirCarrinho">
                           <span class="cart__total-item" v-if="carrinho.itens.length > 0">{{ carrinho.itens.length }}</span>
                        </a>
                        <div class="cart__mini pb-4 pt-3">
                           <div class="cart__close">
                              <button type="button" class="cart__close-btn" @click="fecharCarrinho"><i class="fal fa-times"></i></button>
                           </div>
                           <ul>
                              <li>
                                 <div class="cart__title pb-12">
                                    <h4 class="font-16">Carrinho</h4>
                                    <span class="ms-2 text-secondary" v-if="carrinho.itens.length > 0"> {{ carrinho.itens.length }} produto(s)</span>
                                 </div>
                              </li>

                              <carrinho v-for="(produto, index) in carrinho.itens" :key="index" :produto="produto" :index="index" />
                              
                              <li>
                                 <div class="cart__item d-flex justify-content-between align-items-center" v-if="carrinho.itens.length == 0">
                                    <div class="cart__inner d-flex mx-auto my-5">
                                       <div class="cart__details">
                                          <h6><a href="javascript:;">Carrinho vazio</a></h6>
                                       </div>
                                    </div>
                                 </div>
                              </li>
                              <li v-if="carrinho.itens.length > 0">
                                 <div class="cart__sub d-flex justify-content-between align-items-center">
                                    <h6>Total</h6>
                                    <span class="cart__sub-total" v-if="parseFloat(carrinho.valorTotal) > 0">R${{ parseFloat(carrinho.valorTotal).toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits:2}) }}</span>
                                    <span class="cart__sub-total" v-else>Gratuito</span>
                                 </div>
                              </li>
                              <li v-if="carrinho.itens.length > 0">
                                 <div class="row">
                                    <div class="col-6 pe-1">
                                       <a href="javascript:;" class="t-y-btn t-y-btn-2 w-100" @click="limparCarrinho">Limpar carrinho</a>
                                    </div>
                                    <div class="col-6 ps-1">
                                       <a href="javascript:;" class="t-y-btn w-100" @click="mercadoPago">Finalizar pedido</a>
                                    </div>
                                 </div>
                              </li>
                           </ul>
                        </div>
                     </div>
                     <a href="javascript:;" @click="setRouteAdmin" class="login__icon mt-md-1 mb-2 mb-md-0" v-show="!isLogado"></a>
                  </div>
               </div>

               <!-- Categorias -->
               <div class="row align-items-center mt-1 d-none d-xxl-flex" v-if="tags != null && tags.length > 0">
                  <div class="col-xl-2 col-lg-3"></div>
                  <div class="col-xl-8 col-lg-6">
                     <div class="header__action main-menu">
                        <ul class="d-flex justify-content-center color-theme">
                           <tag v-for="(tag, index) in tags" :key="index" :tag="tag" :page="'Header'" :length="tags.length" />
                        </ul>
                     </div>
                  </div>
                  <div class="col-xl-2 col-lg-3"></div>
               </div>
            </div>
         </div>
      </div>
   </header>
</template>

<script>

import $ from 'jquery'
import router from '@/router'
import { mapState } from 'vuex'
import tag from '@/components/home/Tag.vue'
import carrinho from '@/components/perfil/Carrinho.vue'

export default {
	name: 'Header',
   data : function () {
      return {
         pesquisa: {'tag': 'Todos', 'valor': '', 'filtro': 'Mais recentes'},
         tags: ['Todos', 'Cursos', 'Bases', 'Scripts', 'Favelas', 'Veiculos', 'Skin armas', 'Viaturas', 'Roupas', 'Roupas facção', 'Mapas', 'Free']
      }
   },
   computed: {
		... mapState({
			dadosUsuario: state => state.dadosUsuario,
			carrinho: state => state.carrinho,
			isLogado: state => state.isLogado
		})
	},
   components: {
      tag, carrinho
   },
   methods: {
      setRouteAdmin : function () {
         this.$store.dispatch('isRouteAdmin', true)
         router.push('/admInicio')
      },
      pesquisar : function () {
         this.$store.dispatch('pesquisar', JSON.parse(JSON.stringify(this.pesquisa)))
      },
      deslogar : function () {
			this.$store.dispatch('deslogar')
		},
      abrirMenu : function () {
         $(".offcanvas__area").addClass("opened");
         $(".body-overlay").addClass("opened");
      },
      abrirCarrinho : function () {
         $(".cart__mini").addClass("cart__opened");

         if (this.carrinho.toggle == false) {
            this.$store.dispatch('toggleCarrinho', true)

         } else if (this.carrinho.toggle == true) {
            this.$store.dispatch('toggleCarrinho', false)
         }
      },
      fecharCarrinho : function () {
         this.$store.dispatch('toggleCarrinho', false)
      },
      mercadoPago : function () {
         if (parseFloat(this.carrinho.valorTotal) > 0) {
            this.$store.dispatch('mercadoPago')
         } else {
            this.$store.dispatch('setPacotesGratuitos')
         }
      },
      limparCarrinho : function () {
         this.$store.dispatch('limparCarrinho')
      }
   }
}

</script>

<style scoped>

.btn-category {
   color: #fff !important;
   font-size: 13px !important;
   box-shadow: none !important;
   padding: 3px 12px !important;
   text-transform: capitalize !important;
   font-weight: 400 !important;
}

.header__info-right {
   padding-top: 6px;
   padding-bottom: 6px;
}

</style>