<template>
   <li>
      <div class="cart__item d-flex justify-content-between align-items-center">
         <div class="cart__inner d-flex align-items-center">
            <div class="cart__thumb">
               <a href="javascript:;" class="ratio ratio-1x1">
                  <img :src="produto.nomeArquivo == null ? '' : produto.nomeArquivo" alt="produto" @error="imageError">
               </a>
            </div>
            <div class="cart__details">
               <h6 class="limitador-2">{{ produto.nome }}</h6>
               <div class="cart__desc limitador">{{ produto.descricao == null ? 'Sem descrição' : produto.descricao }}</div>
               <div class="cart__price limitador">
                  <span v-if="parseFloat(produto.valor) > 0">R$ {{ produto.valor == null ? '-' : parseFloat(produto.valor).toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits:2}) }}</span>
                  <span v-else>Gratuito</span>
               </div>
            </div>
         </div>
         <div class="cart__del">
            <a href="javascript:;" @click="removeCarrinho"><i class="far fa-trash text-danger"></i></a>
         </div>
      </div>
   </li>
</template>

<script>

export default {
   name: 'Carrinho',
   props: ['produto', 'index'],
   methods: {
      removeCarrinho: function () {
         this.$store.dispatch('removeCarrinho', this.index)
      },
      imageError : function (e) {
         this.$store.dispatch('imageError', e)
      }
   }
}

</script>

<style scoped>

img {
	aspect-ratio: 1 / 1;
	object-fit: cover;
}

</style>