<template>
   <div class="sidebar-wrapper" data-simplebar="true">
      <div class="sidebar-header">
         <div>
            <img src="@/assets/admin/images/logo-icon.png" class="logo-icon" alt="logo icon">
         </div>
         <div>
            <img src="@/assets/admin/images/logo-text.png" class="logo-img-text" alt="logo text">
         </div>
         <div class="toggle-icon ms-auto" @click="toggleMobileMenu"><i class="fal fa-arrow-to-left font-18"></i></div>
      </div>

      <ul class="metismenu pb-5" id="menu">
         <li class="my-2" :class="$route.path == '/admInicio' ? 'mm-active' : ''">
            <router-link to="/admInicio" class="px-2">
               <div class="parent-icon"><i class="far fa-home"></i></div>
               <div class="menu-title">Inicio</div>
            </router-link>
         </li>

         <li class="menu-label pt-2">Cliente</li>
         <li class="my-0" :class="$route.name == 'AdmMeusCursos' || $route.name == 'AdmAula' || $route.name == 'AdmClassroom' ? 'mm-active' : ''">
            <router-link to="/admMeusCursos" class="px-2">
               <div class="parent-icon"><i class="far fa-backpack"></i></div>
               <div class="menu-title">Meus cursos</div>
            </router-link>
         </li>
         <li class="my-0" :class="$route.path == '/admMeusScripts' ? 'mm-active' : ''">
            <router-link to="/admMeusScripts" class="px-2">
               <div class="parent-icon"><i class="far fa-file-alt"></i></div>
               <div class="menu-title">Meus scripts</div>
            </router-link>
         </li>
         <li class="my-0" :class="$route.path == '/admCompras' ? 'mm-active' : ''">
            <router-link to="/admCompras" class="px-2">
               <div class="parent-icon"><i class="far fa-history"></i></div>
               <div class="menu-title">Histórico de compras</div>
            </router-link>
         </li>
         <li class="my-0" :class="$route.path == '/admAjuda' ? 'mm-active' : ''">
            <router-link to="/admAjuda" class="px-2">
               <div class="parent-icon"><i class="far fa-question"></i></div>
               <div class="menu-title">Ajuda</div>
            </router-link>
         </li>
         <li class="my-0" :class="$route.path == '/admDiscord' ? 'mm-active' : ''">
            <router-link to="/admDiscord" class="px-2">
               <div class="parent-icon"><i class="fab fa-discord"></i></div>
               <div class="menu-title">Discord</div>
            </router-link>
         </li>

         <li class="menu-label" v-if="dadosUsuario.isAdmin || dadosUsuario.isSuporte">Administrativo</li>
         <li class="my-0" :class="$route.path == '/admEstatisticas' ? 'mm-active' : ''" v-if="dadosUsuario.isAdmin || dadosUsuario.isSuporte">
            <router-link to="/admEstatisticas" class="px-2">
               <div class="parent-icon"><i class="far fa-chart-bar"></i></div>
               <div class="menu-title">Estatísticas</div>
            </router-link>
         </li>
         <li class="my-0" :class="$route.name == 'AdmGerenciar' || $route.name == 'AdmModulo' ? 'mm-active' : ''" v-if="dadosUsuario.isAdmin || dadosUsuario.isSuporte">
            <router-link to="/admGerenciar" class="px-2">
               <div class="parent-icon"><i class="far fa-cog"></i></div>
               <div class="menu-title">Gerenciar</div>
            </router-link>
         </li>
         <li class="my-0" :class="$route.path == '/admPedidos' ? 'mm-active' : ''" v-if="dadosUsuario.isAdmin || dadosUsuario.isSuporte">
            <router-link to="/admPedidos" class="px-2">
               <div class="parent-icon"><i class="far fa-shopping-cart"></i></div>
               <div class="menu-title">Pedidos</div>
            </router-link>
         </li>
         <li class="my-0" :class="$route.path == '/admUsuarios' ? 'mm-active' : ''" v-if="dadosUsuario.isAdmin || dadosUsuario.isSuporte">
            <router-link to="/admUsuarios" class="px-2">
               <div class="parent-icon"><i class="far fa-users-class"></i></div>
               <div class="menu-title">Usuários</div>
            </router-link>
         </li>
         <li class="my-0" :class="$route.path == '/admLogs' ? 'mm-active' : ''" v-if="dadosUsuario.isAdmin || dadosUsuario.isSuporte">
            <router-link to="/admLogs" class="px-2">
               <div class="parent-icon"><i class="far fa-terminal"></i></div>
               <div class="menu-title">Logs</div>
            </router-link>
         </li>
      </ul>
   </div>
</template>

<script>

import $ from 'jquery'
import { mapState } from 'vuex'

export default {
	name: 'MenuLateral',
   computed: {
      ... mapState({
         dadosUsuario: state => state.dadosUsuario
      })
   },
   methods: {
      // imageError : function (e) {
      //    this.$store.dispatch('imageError', e)
      // },
      // perfil : function () {
      //    this.$router.push('/admPerfil');
      // },
      // deslogar : function () {
		// 	this.$store.dispatch('deslogar')
      // },
      toggleMobileMenu : function () {
         $(".wrapper").hasClass("toggled") ? ($(".wrapper").removeClass("toggled"), $(".sidebar-wrapper").unbind("hover")) : ($(".wrapper").addClass("toggled"), $(".sidebar-wrapper").hover(function() {
            $(".wrapper").addClass("sidebar-hovered")
         }, function() {
            $(".wrapper").removeClass("sidebar-hovered")
         }))
      }
   }
}

</script>